@import "variables";
@import "~@truenorthmortgage/olympus/src/sass/style";
@import "common";
@import "Forms/forms";
@import "Modals/modal.scss";

#login-page {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gray;
  height: 100vh;
  width: 100%;

  .login-form {
    max-width: 300px;
    text-align: center;

    .button {
      margin-top: 20px;
      background-color: $primary;
      background-image: none;
      color: #fff;
    }
  }
}

.container-widget.well {
  padding: 1rem 0 0 0;
}

#nav .nav-inner .logo {
  padding: 23px 20px 32px 20px;
}

// Olympus form
.container-widget .container-widget-inner {
  display: unset !important;
}

.container-widget.well {
  padding: 1rem !important;
}

.action-header-main {
  padding-left: 0;
}

.content-nav-inner {
  .fa-fw {
    margin-right: 5px;
  }
}
.total_notification_child {
  position: absolute;
  top: -8px;
  right: -5px;
  .fa-circle {
    color: red;
    position: absolute;
    right: -22px;
    top: -11px;
  }
}
.total_notification {
  position: absolute;
  top: -8px;
  right: 0;
  .fa-circle {
    color: red;
    position: absolute;
    right: -22px;
    top: -11px;
  }
}

.fa-layers-counter {
  font-size: 30px;
}

.total_notification_child {
  position: absolute;
  top: -8px;
  right: -5px;
  .fa-circle {
    color: red;
    position: absolute;
    right: -22px;
    top: -11px;
  }
}

.table-row-total {
  td {
    background-color: #dfe2e5 !important;
    font-weight: bold !important;
  }
}

#nav {
  .nav-inner {
    .primary-nav {
      li {
        a {
          span {
            .fa-solid {
              margin-right: 0 !important;
            }
          }
        }
      }
    }
  }
}

#app {
  position: relative;
}

.global-search {
  position: absolute;
  z-index: 100;
  right: 28px;
  top: 23px;
  max-width: 70%;
  width: 70%;
  display: flex;
  .form-container {
    width: 100%;
    .search-component {
      margin-left: 20%;
      width: 71%;
      #global_search {
        padding: 10px 10px 12px 35px;
      }
      .fa-magnifying-glass {
        margin-top: -4px;
      }
    }
  }
  .fa-magnifying-glass {
    margin-left: auto;
    margin-top: 8px;
    margin-right: 8px;
  }
  .fa-xmark {
    margin-left: auto;
    margin-top: 8px;
    margin-right: 8px;
    z-index: 200;
  }
  .side-by-side-element {
    flex-basis: 90%;
    width: 0;
    overflow: hidden;
    transition: width 0.5s ease-out; /* Smooth transition for width */
    display: flex;
    align-items: center;
    padding-left: 10% !important;
  }
}

.investment-accounts-search-results-container {
  h1 {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 2.25rem;
    color: #6f4f9e;
    padding-right: 24px;
    margin-top: 15px;
  }
}

.investor-search-results-container {
  h1 {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 2.25rem;
    color: #6f4f9e;
    padding-right: 24px;
    margin-top: 15px;
  }
}

.investment-search-results-container {
  h1 {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 2.25rem;
    color: #6f4f9e;
    padding-right: 24px;
  }
}

.users-search-results-container {
  margin-top: 20px;
  h1 {
    margin-bottom: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 2.25rem;
    color: #6f4f9e;
    padding-right: 24px;
  }
}

.fade-in {
  animation: fadeIn 0.3s ease-in-out forwards;
}

.fade-out {
  animation: fadeOut 0.3s ease-in-out forwards;
}

/* Fading out animation */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Fading out animation */
@keyframes fadeIn {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
