@import "../variables";

.basic-modal {
  .modal-content {
    white-space: pre-line;
  }

  .title-wrap {
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  }

  .message-wrap {
    margin-top: 20px;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .modal-button-wrap {
    display: flex;
    padding-bottom: 20px;

    .submit-button {
      margin-left: auto;
      margin-top: 20px;
      background-image: none;
      background-color: $primary;
      color: white;

      .svg-inline--fa {
        margin-right: 10px;
      }

      &:hover {
        background-image: none;
        background-color: $primary;
        color: white;
      }
    }
  }
}

.modal-box {
  max-width: 800px !important;
}

.confirmation-modal {
  .modal-box {
    max-width: 580px !important;
  }
}
