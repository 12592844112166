@import "variables";

.side-by-side-element {
  display: inline-block;
  flex-basis: 50%;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-bottom-margin {
  margin-bottom: 0;
}

.no-padding-top {
  padding-top: 0 !important;
}

.full {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.italic {
  font-style: italic !important;
}

.flex {
  display: flex;
}

.grey {
  color: $grey !important;
}

.red {
  color: $red;
}

.red-important {
  color: $red !important;
}

.green {
  color: $green;
}

.green-important {
  color: $green !important;
}

.text-uppercase {
  text-transform: capitalize !important;
}

.dark-grey {
  color: $dark_grey;
}

.background-grey {
  background-color: $dark_grey !important;
}

.background-green {
  background-color: $green !important;
}

.no-search-padding {
  table {
    tbody {
      .faux-row {
        .faux-cell {
          padding-left: 0 !important;
          padding-right: 0 !important;
        }
      }
    }
  }
}

.no-view-padding {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.capitalize {
  text-transform: capitalize;
}

.right-column {
  margin-left: auto !important;
}

.submit-button {
  margin-right: 28px;
}

.action-queue-fund-container {
  .submit-button {
    margin-right: 28px;
    &:hover {
      background-color: $green !important;
    }
  }
}

.submit-search-button {
  margin-left: 10px;
}

.hidden {
  display: none !important;
}

.progress-wrap {
  .progress-bar-max {
    position: relative;
    top: 10px;
    color: #c90000;
    div {
      width: 20px;
      border-left: solid 2px #c90000;
      span {
        top: 11px;
        position: relative;
        left: -11px;
        font-size: 10px;
      }
    }
  }
  .progress-bar {
    height: 5px;
    background-color: #eeeeee;
    border-radius: 1px;
    display: flex;
    .progress-bar-section {
      flex: 1;
    }
    .section1 {
      background-color: #3c984b;
    }
    .section2 {
      background-color: #f9ad40;
    }
    .section3 {
      background-color: #eee;
    }

    .section-1 {
      background-color: #3c984b;
    }
    .section-2 {
      background-color: #c90000;
    }
    .section-3 {
      background-color: #f5a623;
    }
    .section-4 {
      background-color: #eee;
    }
  }
  .progress-bar-text {
    margin: 15px 0px 10px 1px;
    color: #354052;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;

    p {
      margin-bottom: 5px !important;
      color: #354052;
    }

    .section-1 {
      color: #3c984b;
    }
    .section-2 {
      color: #c90000;
    }
    .section-3 {
      color: #f5a623;
    }
    .section-4 {
      color: #354052;
    }

    .color_section1 {
      color: #3c984b;
      font-weight: bold;
    }
    .color_section2 {
      color: #f9ad40;
      font-weight: bold;
    }
    .color_section3 {
      color: #eee;
      font-weight: bold;
    }
  }
}

.no-left-right-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.faux-row {
  &.complete-row {
    background-color: $completed !important;
  }

  &.disabled-row {
    background-color: $light-grey !important;
  }
}

.inner-button-wrap {
  margin-left: auto;
}

.search-wrap {
  display: flex;
  flex-basis: 100%;
  .search-component {
    flex-basis: 50%;
  }
  margin-bottom: 10px;
  .column {
    &.search-select {
      flex-basis: max-content;
      margin-left: auto;
    }
  }
}

.pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 27px;
}

.search-with-pagination {
  width: 100%;
}

.wrap {
  display: flex;

  .warning {
    padding-top: 4px;
  }

  label {
    padding-top: 4px;
  }

  #icon {
    padding-left: 10px;
  }
}

.checkbox_readonly {
  color: $primary;
  font-size: 18px;
  padding-right: 5px;
}

.color-red {
  color: $red;
}

.new-button {
  background-image: none;
  background-color: $green;
  color: white !important;
  &:hover {
    background-image: none;
    background-color: $green;
    color: white !important;
    opacity: 0.8;
  }
}

.cancel-button {
  background-image: none;
  background-color: $gold;
  color: white !important;
  &:hover {
    background-image: none;
    background-color: $gold;
    color: white !important;
    opacity: 0.8;
  }
}

.delete-button {
  background-image: none;
  background-color: $red;
  color: white !important;
  &:hover {
    background-image: none;
    background-color: $red;
    color: white !important;
    opacity: 0.8;
  }
}

.hr {
  color: $grey;
  opacity: 0.3;
}

.uppercase {
  text-transform: uppercase !important;
}

input[name="postal_code"] {
  text-transform: uppercase !important;
}

.input_wrapper_icon {
  position: absolute;
  padding: 30px 0px 0px 25px;
  color: $dark_grey;
}
.input_wrapper_padding {
  input {
    padding-left: 30px !important;
  }
}

.right {
  text-align: right !important;
}

.tab-group-tab {
  padding-top: 10px;
}

.search_icon {
  padding: 9px 4px 9px 40px !important;
  background: transparent
    url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 512 512" %3E%3Cpath d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"%3E%3C/path%3E%3C/svg%3E')
    no-repeat 13px center !important;
  opacity: 0.5;
}

.faux-cell {
  .action-buttons {
    display: block;
    text-align: right;
  }
}

.faux-cell.action_buttons {
  padding-right: 0px !important;
}

.actions-dropdown {
  text-align: left;
  max-width: 300px;
  padding-left: 0;
}

.filter-wrapper {
  padding: 0px 6px 0px 6px !important;
}

.disabled_input_wrapper_icon {
  position: absolute;
  padding: 8px 0px 0px 13px;
  z-index: 1;
  &.charcoal {
    color: $charcoal;
  }
}

.disabled_input_wrapper_padding {
  padding-left: 30px !important;
}

.nowrap {
  white-space: nowrap;
  flex-wrap: unset;
}

.status_table {
  &.investment {
    padding-left: 0;
  }
  padding: 0px 30px 0px 30px;
  td {
    border-bottom: none !important;
  }
}

// Override the padding to line up the items !
.widget .widget-inner .search-component input {
  padding: 12px 10px 10px 35px !important;
}

.investments-list-table {
  .completed-row {
    background-color: $completed !important;
  }

  .draft-row {
    background-color: $draft !important;
  }

  .deleted-row {
    background-color: $deleted !important;
  }

  .inactive-row {
    background-color: $deleted !important;
  }
}

.property-map {
  border-radius: 15px;
  height: 400px;
  width: 100%;
  &.map-loaded {
    height: 400px;
    width: 100%;
  }
}

.geocode-results-warning {
  display: flex;
  #icon {
    margin-top: 5px;
    margin-left: 10px;
    color: $red;
  }
}
/*======= Map Styling ============*/
.gmnoprint a,
.gmnoprint span {
  display: none;
}
.gmnoprint div {
  background: none !important;
}
#GMapsID div div a div img {
  display: none;
}

// remove the wrapping container
.gm-style-cc:last-child {
  display: none !important;
}
// remove the link only
a[title="Report errors in the road map or imagery to Google"] {
  display: none !important;
}

.left-aligned {
  padding-left: 0 !important;
  p {
    text-align: left;
  }
}

.side-by-side-element.column:last-of-type {
  padding: 0px 10px 0px 10px;
}

.widget a {
  font-weight: bold;
}

.bold {
  font-weight: bold !important;
}

.footer-table {
  background: #dee2e5 !important;
}

.status-tag {
  td {
    border-bottom: none !important;
  }
  .status-tag-cell {
    float: left !important;
    position: relative !important;
    top: -10px !important;
    padding-left: 0px !important;
  }
  .status-tag-subtitle {
    color: $grey !important;
    float: left !important;
    font-family: "proxima-nova", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 1.125rem !important;
    position: relative !important;
    top: -10px !important;
    left: -15px !important;
  }
  .status-tag-active {
    div {
      background-color: $green;
    }
  }
  .status-tag-expired {
    div {
      background-color: $red;
    }
  }
  .status-tag-complete {
    div {
      background-color: $green;
    }
  }
  .status-tag-deleted {
    div {
      background-color: $red;
    }
  }
  .status-tag-inactive {
    div {
      background-color: $red;
    }
  }
  .status-tag-pending {
    div {
      background-color: $gold;
    }
  }
  .status-tag-submitted {
    div {
      background-color: $green;
    }
  }
  .status-tag-cancelled {
    div {
      background-color: $red;
    }
  }
  .status-tag-paid {
    div {
      background-color: $red;
    }
  }
  .status-tag-paid_out {
    div {
      background-color: $red;
    }
  }
  .status-tag-renewed {
    div {
      background-color: $gold;
    }
  }
}

.no-padding-bottom {
  .widget {
    margin-bottom: 0px !important;
  }
}

.no-header-padding-left {
  .widget {
    padding-left: 0px !important;
  }
}

.margin-left-auto {
  margin-left: auto;
}

.header-padding-left {
  .widget {
    padding-left: 15px !important;
  }
}

.multi-step-bread-crumbs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  .multistep-breadcrumb {
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    .fa-circle-check {
      font-size: 20px;
      &.complete {
        color: $green;
      }
    }
    .fa-circle {
      color: $grey;
      margin-bottom: 12px;
      font-size: 20px;
    }
  }
  .breadcrumb-spacer {
    width: 88px;
    display: flex;
    .next-step-caret {
      margin-top: 10px;
      color: $primary;
    }
    .inner-bar {
      background-color: $primary;
      height: 3px;
      width: 100%;
      margin-top: 16px;
    }
  }
}

.full-width-form {
  width: 100%;
}

.completed-breadcrumb {
  font-weight: bold !important;
  color: black !important;
}

.current-breadcrumb {
  font-weight: bold !important;
  p {
    color: $dark_grey;
  }
}

.link {
  cursor: pointer;
  &:hover {
    color: $primary;
  }
}

.left-padding {
  padding-left: 15px;
}

.generic-modal-container {
  overflow-x: clip;
  .center {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    h2 {
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }
  }
  .generic-modal-button {
    button {
      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.label-icon-wrap {
  display: flex;
  label {
    margin-top: 5px;
  }
  #icon {
    margin-bottom: 5px;
  }
  .fa-circle-question {
    color: $grey;
    font-size: 14px;
  }
}

.no-right-left-margin {
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.no-right-margin {
  margin-right: 0 !important;
}

.date-icon-wrap {
  position: relative;
  .fa-calendar {
    position: absolute;
    top: 30px;
    left: 9px;
  }
  .flatpickr-input {
    padding-left: 27px;
    padding-top: 10px;
  }
}

.top-margin {
  margin-top: 6px;
}

.radios-wrapper {
  .radios {
    width: fit-content;
  }
}

.checkbox {
  width: fit-content;
}

.pre-line {
  white-space: pre-line !important;
}

.grade-a-tag div {
  background-color: #21b092;
  color: white;
}

.grade-b-tag div {
  background-color: #fbac33;
  color: white;
}

.grade-c-tag div {
  background-color: #e45d5c;
  color: white;
}

.grade-d-tag div {
  background-color: #9d9d97;
  color: white;
}

.grade-e-tag div {
  background-color: #9d9d97;
  color: white;
}

.grade-i-tag div {
  background-color: #9d9d97;
  color: white;
}

.grade-u-tag div {
  background-color: #9d9d97;
  color: white;
}

button:disabled {
  opacity: 0.6 !important;
  background: #eeeeee !important;
  color: #7f8fa4 !important;
  &:hover {
    color: #7f8fa4 !important;
  }
}

.transaction_highlight td {
  font-weight: bold !important;
}

.no-right-padding {
  padding-right: 0px !important;
}
