@import "../variables";

.form-error-message {
  color: red;
  margin-top: 5px;
  display: block;
  padding-left: 14px;
}

.form {
  .submit-button {
    margin-left: auto;
    margin-top: 20px;
    background-image: none;
    background-color: $primary;
    color: white;

    .svg-inline--fa {
      margin-right: 10px;
    }

    &:hover {
      background-image: none;
      background-color: $primary;
      color: white;
    }
  }
}

.checkbox-wrap {
  .column {
    &.undefined {
      padding-left: 0;

      .checkbox input:checked ~ .checkmark {
        border: 1px solid $primary;
        background-image: linear-gradient(
          to top,
          $primary 0%,
          $primary-light 100%
        );
      }
    }
  }
  label.checkbox {
    width: fit-content;
  }
}
