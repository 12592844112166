/* Set custom system values */
$primary: #6f4f9e;
$primary-light: #6f4f9e;
$nav-background-color: $primary;
$grey: #7f8fa4;
$red: #c90000;
$green: #3c984b;
$dark_grey: #354052;
$light-grey: #ebedef;
$completed: #ebf5ed;
$charcoal: #354052;
$draft: #fff4d3;
$deleted: #fccbcb;
$gold: #f5a623;
